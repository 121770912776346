<template>
  <v-img :height="height" :max-width="height"  src="/library/img/westudy_logo_small.png"></v-img>
</template>

<script>
export default {
  name: "wsLogo",
  props : {
    height : {
      type : String,
      default : '32'
    }
  }
}
</script>

<style scoped>

</style>